
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { defineComponent, ref } from "vue";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import NotificationService from "@/buying-teams/services/NotificationService";

export default defineComponent( {
  name: "RecoverPassword",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const successMessage = ref(false)

    const checkEmail = Yup.object().shape({
      email: Yup.string().email().required().label("Email")
    });

    const submitButton = ref<HTMLButtonElement | null>(null);

    const onSubmitRecoverPassword = (values) => {
      if (submitButton.value) {
        submitButton.value!.disabled = true;
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.RECOVER_PASSWORD, values)
        .then((res) => {
          if (res.status === 200) {
            successMessage.value = true
            return
          }

          successMessage.value = false
        })
        .catch(err => {
          NotificationService.swalError(err)
          successMessage.value = false
        })
        .finally(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (submitButton.value) submitButton.value.disabled = false;
        });
    }

    return {
      onSubmitRecoverPassword,
      checkEmail,
      submitButton,
      successMessage
    }
  }
})
